<template>
  <default-layout>
    <v-container fluid tag="section">
      <area-form />
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import { mapState } from 'vuex';
const AreaForm = () => import('@/components/master-data/area/form/area-form');

export default {
  name: 'agent-office-edit',
  components: {
    DefaultLayout,
    AreaForm,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({}),
  },
  async created() {
    await this.$store.dispatch('area/form/restoreInitialState');
    this.$store.commit('area/form/SET_FORM_TYPE', 'EDIT');
    this.$store.commit('area/form/SET_ID', this.$route.params.id);
    await this.$store.dispatch('area/form/fetchBaseData');
  },
};
</script>
